import React, { useState } from "react";
import {
  contactForm,
  contactRow,
  contactColumn,
  hp,
  answer,
  success,
  error,
} from "./contact-form.module.scss";

export const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    "form-name": "Kontaktformular",
    "bot-field": "",
    firma: "",
    name: "",
    email: "",
    tel: "",
    message: "",
    subject: `Anfrage per Kontaktformular`,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      await fetch(`/`, {
        method: `POST`,
        body: encode(formData),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });

      setFormData({
        ...formData,
        formResult:
          "Vielen Dank, Ihre Anfrage wurde übermittelt. Wir werden uns in Kürze bei Ihnen melden.",
        formSuccess: true,
        formError: false,
        firma: "",
        name: "",
        email: "",
        tel: "",
        message: "",
      });
      setIsLoading(false);
    } catch (error) {
      setFormData({
        ...formData,
        formResult:
          "Entschuldigung, bei der Übermittlung ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal oder senden Sie Ihre Anfrage direkt an: <a href='mailto:info@tie.ch'>info@tie.ch</a>.",
        formSuccess: false,
        formError: true,
      });
      setIsLoading(false);
    }
  };

  return (
    <form
      className={contactForm}
      name={`Kontaktformular`}
      onSubmit={handleSubmit}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input
        type="text"
        name="bot-field"
        aria-label="Dieses Feld nicht ausfüllen wenn Sie ein Mensch sind"
        placeholder="Dieses Feld nicht ausfüllen wenn Sie ein Mensch sind"
        value={formData["bot-field"]}
        onChange={handleChange}
        className={hp}
      />
      <input type="hidden" name="form-name" value="Kontaktformular" />
      <div className={contactRow}>
        <div className={contactColumn}>
          <input
            type="text"
            name="firma"
            aria-label="Firma"
            placeholder="Firma"
            value={formData.firma}
            onChange={handleChange}
          />
        </div>
        <div className={contactColumn}>
          <input
            type="text"
            name="name"
            aria-label="Name"
            placeholder="Name*"
            required
            value={formData.name}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className={contactRow}>
        <div className={contactColumn}>
          <input
            type="tel"
            name="tel"
            aria-label="Telefon"
            placeholder="Telefon"
            value={formData.tel}
            onChange={handleChange}
          />
        </div>
        <div className={contactColumn}>
          <input
            type="email"
            name="email"
            aria-label="E-Mail"
            placeholder="E-Mail*"
            required
            value={formData.email}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className={contactRow}>
        <div className={contactColumn}>
          <textarea
            name="message"
            aria-label="Mitteilung"
            placeholder="Mitteilung*"
            rows="6"
            required
            value={formData.message}
            onChange={handleChange}
          />
        </div>
      </div>

      <button type="submit" disabled={isLoading}>
        {isLoading ? "Senden..." : "Senden"}
      </button>

      {!!formData.formSuccess && (
        <div
          className={`${answer} ${success}`}
          dangerouslySetInnerHTML={{ __html: formData.formResult }}
        />
      )}
      {!!formData.formError && (
        <div
          className={`${answer} ${error}`}
          dangerouslySetInnerHTML={{ __html: formData.formResult }}
        />
      )}
    </form>
  );
};
